import React, { useEffect, useRef, useState } from "react";
import "../../assets/css/AddCatagorystyle.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import { toast } from "react-toastify";
import { userSchema } from "./validation";
import Resizer from "react-image-file-resizer";
import NewCateogoryModal from "../../features/newCategoryModal/NewCateogoryModal";
import { useSelector, useDispatch } from "react-redux";
import ImageLoader from "../../features/loader/imageLoader";
import heic2any from "heic2any";
import {
  setEditFormVisible,
  setShowAddUser,
} from "../../store/slices/addUserSlice";
import { setAllCategories } from "../../store/slices/newCategoryModalSlice/modalSlice";
import { addUsers, getCategories, updateUsers } from "../../api/api-route";
import { baseImgUrl } from "../../data/Contant";

const AddCategory = ({ selectedUserData, fetchUserData }) => {   
  const [initialValue, setInitialValue] = useState({
    first_name: selectedUserData ? selectedUserData.first_name : "",
    last_name: selectedUserData ? selectedUserData.last_name : "",
    category_id: selectedUserData ? selectedUserData.category_id : "",
    phone_number: selectedUserData ? selectedUserData.phone_number : "",
    email: selectedUserData ? selectedUserData.email : "",
    description: selectedUserData ? selectedUserData.description : "",
    user_image: selectedUserData ? selectedUserData.user_image : null,
  });
  const dispatch = useDispatch();
  const [imageConverted, setImageConverted] = useState(false);
  const [errMessages, setErrMessages] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const modalRef = useRef();
  const categoryModalRef = useRef(null);
  const [categoryModalOpen, setCategoryModalOpen] = useState(false);
  const location = useLocation();
  const shouldShowEditIcon = location.pathname === "/user-update-information";
  const [catLoading, setCatLoading] = useState(false);

  const { allCategories } = useSelector((state) => state.categoryModal);  

  const showAddUser = useSelector((state) => state.addUserForm.showAddUser);
  const editFormVisible = useSelector(
    (state) => state.addUserForm.editFormVisible
  );   

  useEffect(() => {
    getCategories()
      .then((res) => {
        const transformedData = res.data.map((cat) => ({
          value: cat.id,
          label: cat.category,
        }));
        const categoriesWithCustom = [
          ...transformedData,
          { label: 'Custom Category', value: 'custom' },
        ];
        dispatch(setAllCategories(categoriesWithCustom));
      })
      .catch((err) => {
        console.log("error in get categroy api", err);
      });
  }, [dispatch, catLoading]);

  const closeUserForm = () => {
    if (showAddUser) {
      dispatch(setShowAddUser(false));
    }
    if (editFormVisible) {
      dispatch(setEditFormVisible(false));
    }
    document.body.style.overflow = "auto";
  };

  const handleChange = (e, userId = null) => {
    const { name, value } = e.target;
    setInitialValue((prevData) => ({
      ...prevData,
      [name]: name === "image" ? e.target.files[0] : value,
    }));

    setErrMessages((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  useEffect(() => {
    const htmlBody = document.querySelector("html");
    if (modalRef) {
      htmlBody.style.overflow = "hidden";
    } else {
      htmlBody.style.overflow = "auto";
    }
    return () => {
      htmlBody.style.overflow = "auto";
    };
  }, [modalRef]);

  const handleCategoryChange = (e) => {
    const { value } = e.target; 
    if (value === "custom") {
      setCategoryModalOpen(true);
    } else {
      setInitialValue((prevData) => ({
        ...prevData,
        category_id: value,
      }));
    }
  };

  const handleCategoryModalClose = () => {
    setCategoryModalOpen(false);
    setInitialValue((prevState) => ({ ...prevState, category_id: "" })); 
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        100,
        100,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file.type === "image/heic" || file.type === "image/heif") {
      setImageConverted(true);
      setIsSubmitting(true);
      try {
        const jpegBlob = await heic2any({
          blob: file,
          toType: "image/jpeg",
          quality: 0.7, // Adjust the quality as needed
        });
        const jpegFile = new File([jpegBlob], file.name, {
          type: "image/jpeg",
        });

        const image = await resizeFile(jpegFile); 
        setInitialValue((prevState) => ({
          ...prevState,
          user_image: image,
        }));
      } catch (error) {
        console.log("error in image", error);
      } finally {
        setImageConverted(false);
        setIsSubmitting(false);
      }
    } else {
      try {
        const image = await resizeFile(file);
        setInitialValue((prevState) => ({
          ...prevState,
          user_image: image,
        })); 
      } catch (err) {
        console.error(err);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    try {

      if (selectedUserData && selectedUserData.user_image) {
        // If userImage is not being updated, remove validation for userImage field
        userSchema.omit("user_image");
      } else {
        await userSchema.validate(initialValue, { abortEarly: false });
      }

      // If selectedUserData exists, update the existing user
      if (selectedUserData) { 
        const { id } = selectedUserData;
        const modified = { ...initialValue };
        try {
          const response = await updateUsers(id, modified);  
          toast.success("user update successfully!");
        } catch (error) {
          toast.error("Failed to update user")
        } finally {
          await fetchUserData();
        }
      } else { 
        try {
          const response = await addUsers(initialValue)  
          toast.success("User create successfully");
        } catch (error) {
          toast.error("Failed to add user");
        }finally{
          await fetchUserData(); 
        } 
      }
      // Clear form fields after successful submission
      resetForm();
      // Close the form
      closeUserForm();
    } catch (error) {
      if (error && error.inner) {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setErrMessages(validationErrors);
      }
      toast.error("Failed to add/update user. Please check your input."); 
    } finally {
      setIsSubmitting(false);
    }
  };

  const resetForm = (e) => {
    setInitialValue({
      first_name: "",
      last_name: "",
      category_id: "",
      phone_number: "",
      email: "",
      description: "",
      user_image: null,
    });
  };

  const onChangeCategory = (e) => {
    handleChange(e);
    handleCategoryChange(e);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target) &&
        categoryModalRef.current &&
        !categoryModalRef.current.contains(event.target)
      ) {
        closeUserForm();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showAddUser, editFormVisible]);

  return (
    <div>
      <NewCateogoryModal
        categoryModalRef={categoryModalRef}
        categoryModalOpen={categoryModalOpen}
        handleCategoryModalClose={handleCategoryModalClose}
        setCatLoading={setCatLoading}
      />
      <div className="modal_overlay"></div>
      <div className="main-Container" id="mainContainer" ref={modalRef}>
        <div className="contactform d-flex align-item-center justifly-content-between">
          {shouldShowEditIcon ? (
            <h4>Update User Info</h4>
          ) : (
            <h4>Add New User</h4>
          )}

          <button onClick={closeUserForm} className="close-btn">
            <IoMdClose />
          </button>
        </div>
        <div className="form-container">
          <form className="contact-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <div className="name-field-label">
                <label htmlFor="first_name">First Name </label>
              </div>
              <div className="name-field">
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  placeholder="Enter  first name"
                  value={initialValue.first_name}
                  onChange={handleChange}
                />
                {errMessages.first_name && (
                  <p className="text-danger">{errMessages.first_name}</p>
                )}
                <label htmlFor="last_name">Last Name </label>
                <input
                  type="text"
                  id="last_name"
                  name="last_name"
                  placeholder="Enter last name"
                  value={initialValue.last_name}
                  onChange={handleChange}
                />
                {errMessages.last_name && (
                  <p className="text-danger">{errMessages.last_name}</p>
                )}
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone Number</label>
              <input
                type="text"
                id="phone"
                name="phone_number"
                placeholder="Enter phone number"
                value={initialValue.phone_number}
                onChange={handleChange}
                onKeyPress={(e) => {
                  const charCode = e.charCode;
                  if (
                    !/\d/.test(String.fromCharCode(charCode)) &&
                    charCode !== 8 &&
                    (charCode < 37 || charCode > 40)
                  ) {
                    e.preventDefault();
                  }
                }}
                maxLength={10} // Limit input length to 10 characters
              />
              {errMessages.phone_number && (
                <p className="text-danger mt-2">{errMessages.phone_number}</p>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="category">Category</label>
              <select
                className="form-select"
                id="category_id"
                name="category_id"
                value={initialValue.category_id} 
                onChange={onChangeCategory}
              >
                <option value="" disabled selected>
                  Select category menu
                </option>
                {allCategories.map((category) => (
                  <option key={category.value} value={category.value}>
                    {category.label}
                  </option>
                ))}
              </select>
              {errMessages.category_id && (
                <p className="text-danger mt-2">{errMessages.category_id}</p>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter email address"
                value={initialValue.email}
                onChange={handleChange}
              />
              {errMessages.email && (
                <p className="text-danger mt-2">{errMessages.email}</p>
              )}
            </div>
            <div className="form-group">
              {shouldShowEditIcon ? (
                <>
                  {selectedUserData && selectedUserData.user_image && (
                    <div className="mb-3">
                    <label>Existing Image:</label>
                    <img
                      src={`${baseImgUrl}/${selectedUserData.id}/${selectedUserData.user_image}`}
                      alt="User"
                      style={{ width: "100px" }}
                    />
                    </div>
                  )}

                  <label htmlFor="user_image">User Image</label>
                  <input
                    type="file"
                    id="user_image"
                    name="user_image" 
                    onChange={handleImageChange}
                  />
                </>
              ) : (
                <>
                  <label htmlFor="user_image">User Image</label>
                  <input
                    type="file"
                    id="user_image"
                    name="user_image"
                    onChange={handleImageChange}
                  />
                </>
              )}
              {imageConverted ? (
                <>
                  <ImageLoader />
                </>
              ) : null}
            </div>
            <div className="form-group">
              <label htmlFor="message">Skills descriptions</label>
              <textarea
                id="description"
                defaultValue={""}
                name="description"
                placeholder="Write something about user"
                value={initialValue.description}
                onChange={handleChange}
              />
              {errMessages.description && (
                <p className="text-danger">{errMessages.description}</p>
              )}
            </div>
            <button className="btn btn-dark mb-4 submitbtn" type="submit">
              {isSubmitting ? "Submitting..." : "Submit"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddCategory;
