import * as yup from 'yup';

export const userSchema = yup.object().shape({
      first_name: yup.string()
            .required("First name is required"),
      last_name: yup.string()
            .required("Last name is required"),
      category_id: yup.string()
            .required("Category is required"),
      phone_number: yup.string()
            .required("Phone number is required.")
            .matches(/^[0-9]{10}$/,  "Mobile number must be exactly 10 digits"),
      email: yup.string()
            .email("Invalid email").required("Email is required"),
      description: yup.string()
            .required("Skills descriptions is required"),
     
});
