import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;

const instance = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: "application/json", 
  },
});

export default instance;
