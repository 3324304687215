import React from "react";
import { FaFacebook, FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter, FaYoutube, FaInstagram } from "react-icons/fa6";
import logoWhite from "../../assets/images/Vector ai file 1.png";
import "../../assets/css/FooterStyle.css";
const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row d-flex justify-content-between">
            <div className="col-lg-4 col-md-6 mx-lg-5">
              <div className="footer__about">
                <div className="footer__about__logo">
                  <a href="https://www.webinfinitesolutions.com/" target='_blank'>
                    <img src={logoWhite} alt />
                  </a>
                </div>
                <p>
                  Empowering communities with quick access to essential services. Connect with professionals or contribute by adding new categories. Together, we're building convenience one click at a time.
                </p>
              </div>
            </div>
            <div className="col-lg-6  col-md-8  col-sm-12">
              <div className="footer__address">                
                <ul className="px-0" >
                  <li>
                    <span>Call Us:</span>
                    <p>(+91) 9465532412</p>
                  </li> 
                  <li>
                    <span>Email:</span>
                    <p>hr@webinfinitesolutions.com</p>
                  </li>
                  <li>
                    <span>Connect Us:</span>
                    <div className="footer__social">
                    <a href="https://www.facebook.com/profile.php?id=100093412791155" style={{color : "black"}} target='_blank' rel="noreferrer">
                      <span >
                        <FaFacebook style={{marginBottom:"20px"}}/>
                      </span>
                    </a>
                    <a href="https://twitter.com/WebInfiSol" style={{color : "black", marginLeft:"10px"}} target='_blank' rel="noreferrer" >
                      <span >
                        <FaXTwitter style={{marginBottom:"20px"}} />
                      </span>
                    </a>
                    <a href="https://www.linkedin.com/company/web-infinite-solutions-pvt-ltd/" style={{color : "black", marginLeft:"10px"}} target='_blank' rel="noreferrer" >
                      <span >
                        <FaLinkedinIn style={{marginBottom:"20px"}}/>
                      </span>
                    </a>                                    
                    <a href="https://www.youtube.com/@webinfinitesolutions" style={{color : "black", marginLeft:"10px"}}  target='_blank' rel="noreferrer">
                      <span >
                        <FaYoutube style={{marginBottom:"20px"}}/>
                      </span>
                    </a> 
                    <a href="https://www.instagram.com/webinfinitesolutions" style={{color : "black" , marginLeft:"10px"}} target='_blank' rel="noreferrer" >
                      <span >
                        <FaInstagram style={{marginBottom:"20px"}}/>
                      </span>
                    </a>
                    </div>
                  </li>
                  <li>
                    <span>Address:</span>
                    <p>E-216, Sector-74 Sahibzada Ajit Singh Nagar, Punjab 160055</p>
                  </li>
                </ul>
              </div>
            </div>            
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="footer__copyright">
                <div className="footer__copyright__text col-lg-8 offset-lg-2 text-center">
                  <p>
                    Copyrights © 2024 All Rights Reserved by  &nbsp;
                    <a href="https://www.webinfinitesolutions.com/" target="_blank">
                      Web Infinite Solutions Pvt. Ltd.
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
