import React, {useState } from 'react'
import {  useDispatch } from 'react-redux';
import { appendNewCategory } from '../../store/slices/newCategoryModalSlice/modalSlice'
import { toast } from 'react-toastify';
import { createCategory } from '../../api/api-route';

const NewCateogoryModal = ({
    categoryModalRef,
    categoryModalOpen,
    handleCategoryModalClose,
    categoryOpenModal,
    handleCloseModal,
    sidebarCategoryModalOpen,
    setCatLoading, 
  }) => { 
    const [category, setCategory] = useState({
      category: '',
    });
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const handleKeyDown = (e) => {
      if (e.key === "Enter" && loading) {
        e.preventDefault(); // Prevent the default behavior of Enter when loading is true
      }
    };

  const handleSaveChanges = async (e) => {
    e.preventDefault();
    if (loading) return;
    
    setLoading(true);

    if (category.category === "") {
      toast.error("Fill the input")
      setLoading(false);
      return;
    }

    try {
      const res = await createCategory(category)
 
      toast.success(res.data.message) 
 
      dispatch(
        appendNewCategory({
          label: category.category,
          value: category.category,
        })
      ); 
 
      setCatLoading(true); 

       // Close the modal based on which one is open 
      if(categoryModalOpen){
        handleCategoryModalClose();
      }else{
        handleCloseModal();
      } 

      // Clear the input field 
      setCategory({...category, category: ""}) 
    } catch (error) {
      toast.error("Something went wrong, please try again!");
    } finally{
      setLoading(false);
    }
  };  

  const handlemodalclose = () => {
    if(categoryModalOpen){
      handleCategoryModalClose();
    }else{
      handleCloseModal();
    }
  }    

  return (
    <div className='newCategoryModal'>        
      <div
        className={`modal fade ${(categoryModalOpen || categoryOpenModal || sidebarCategoryModalOpen)  ? "show showModal" : "hide"}`}
        id="categoryModal"
        ref={categoryModalRef}
        tabIndex="-1"
        aria-labelledby="categoryModalLabel"
        aria-hidden={!categoryModalOpen}
      >
        <div className="modal_overlay"></div>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="categoryModalLabel">
                Add new category
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  handlemodalclose();
                }}
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSaveChanges}>
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    New Category:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="recipient-name"
                    placeholder='Enter your new category'
                    value={category.category}
                    onChange={(e) => 
                      setCategory({...category, category: e.target.value})
                    }        
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => {
                  handlemodalclose();
                }}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={(e) => handleSaveChanges(e)}
                disabled={loading}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

NewCateogoryModal.defaultProps = {
  handleCategoryModalClose: () => {}
}

export default NewCateogoryModal