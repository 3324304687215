import React, { useEffect, useState } from "react";
import "../../assets/css/Navbar.css";
import { FaSearch, FaUserCheck } from "react-icons/fa";
import { RiUserAddFill } from "react-icons/ri";
import allServicesIcon from "../../assets/images/customer-service.png";
import serviceIcon from "../../assets/images/icons8-service-64.png";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/Logo.png";
import { useDispatch, useSelector } from "react-redux";
import {
  setHasMore,
  setScrollLoading,
} from "../../store/slices/scrollingSlice/scrollingSlice";
import { LiaUserPlusSolid } from "react-icons/lia";
import { useLocation } from "react-router-dom";
import NewCateogoryModal from "../../features/newCategoryModal/NewCateogoryModal";
import {
  setSearchLoading,
  setSearchQuery,
} from "../../store/slices/searchQuerySlice";
import { setShowAddUser } from "../../store/slices/addUserSlice";
import { setIsSidebarNavOpen } from "../../store/slices/sidebarSlice";
import {
  getCategories,
  getUsers,
  searchCategory,
  searchUser,
} from "../../api/api-route";
import { toast } from "react-toastify";
import { setUserData } from "../../store/slices/userDataSlice";

const Navbar = ({ authenticated }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState("");
  const [categorySearch, setCategorySearch] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const shouldShowEditIcon = location.pathname === "/user-update-information";
  const [categoryOpenModal, setCategoryOpenModal] = useState(false);
  const [catLoading, setCatLoading] = useState(false);
  const [searchDisable, setSearchDisable] = useState(false);

  const { allCategories } = useSelector((state) => state.categoryModal);
  const { passLoading } = useSelector((state) => state.passLoadingSlice);

  const isSidebarOpen = useSelector(
    (state) => state.sidebarNav.isSidebarNavOpen
  );

  useEffect(() => {
    let i = 0;
    let placeholder = "";
    const text = "Search categories and name ...";
    const speed = 300;
    const pauseDuration = 2000;
    let cursorVisible = false;

    function type() {
      if (i < text.length) {
        placeholder += text.charAt(i);
        document
          .getElementById("inputSearch")
          .setAttribute(
            "placeholder",
            placeholder + (cursorVisible ? "|" : "")
          );
        i++;
        setTimeout(type, speed);
      } else {
        setTimeout(backspace, pauseDuration);
      }
    }
    function backspace() {
      if (i >= 0) {
        placeholder = text.substring(0, i);
        document
          .getElementById("inputSearch")
          .setAttribute(
            "placeholder",
            placeholder + (cursorVisible ? "|" : "")
          );
        i--;
        setTimeout(backspace, speed);
      } else {
        i = 0;
        setTimeout(type, speed);
      }
    }
    function toggleCursor() {
      cursorVisible = !cursorVisible;
      document
        .getElementById("inputSearch")
        .setAttribute("placeholder", placeholder + (cursorVisible ? "|" : ""));
      setTimeout(toggleCursor, 300);
    }
    type();
    toggleCursor();
  }, []);

  const handleInputChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
  };

  const handleCategoryClick = async (e, category) => { 
    setSelectedCategory(category.category);
    dispatch(setHasMore(true));
    dispatch(setScrollLoading(false));
    // await handleSearch(e, category);
    try {
      const response = await searchCategory(category.id); 
      if (Array.isArray(response.data)) {
        // If the response is an array, set the new data
        dispatch(setSearchQuery(response.data));
      } else if (
        typeof response.data === "string" &&
        response.data === "Not found"
      ) {
        // If the response is the "Not found" string, clear the data
        dispatch(setSearchQuery([]));
      }
    } catch (error) {
      console.log("error in category search res", error);
    }
  };

  const fetchcategories = () => {
    getCategories()
      .then((res) => {
        setCategories(res.data);
      })
      .catch((err) => {
        console.log("error in get category", err);
        toast.error();
      });
  };

  useEffect(() => {
    fetchcategories();
  }, [catLoading]);

  const handleOpenModal = () => {
    setCategoryOpenModal(true);
  };

  const handleCloseModal = () => {
    setCategoryOpenModal(false);
  };

  const addUserForm = (event) => {
    dispatch(setShowAddUser(true));
    document.body.style.overflowY = "hidden";
    if (event) {
      event.stopPropagation();
    }
  };

  const toggleSidebar = () => {
    dispatch(setIsSidebarNavOpen(!isSidebarOpen));
  };

  const handleSearch = async (e, category) => {
    e.preventDefault();
    if (searchTerm === "" && !category) {
      toast.error("Enter value first");
      return;
    }
    if (searchDisable === true) {
      return;
    }
    if (passLoading) {
      return;
    }
    setSearchDisable(true);
    try {
      dispatch(setSearchLoading(true));

      // Trim the searchTerm to remove any leading or trailing whitespace
      const trimmedSearchTerm = searchTerm.trim();

      const response = await searchUser(trimmedSearchTerm);
      if (Array.isArray(response.data)) {
        // If the response is an array, set the new data
        dispatch(setSearchQuery(response.data));
      } else if (
        typeof response.data === "string" &&
        response.data === "Not found"
      ) {
        // If the response is the "Not found" string, clear the data
        dispatch(setSearchQuery([]));
      }
      // dispatch(setSearchQuery(response.data));
      toast.success("search succussfully");
    } catch (error) {
      console.log("error in search", error);
      return;
    } finally {
      setSearchDisable(false);
    }
  };

  const fetchUserData = () => {
    dispatch(setSearchLoading(true));

    getUsers()
      .then(function (response) {
        // handle success
        dispatch(setUserData(response.data));
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
        dispatch(setSearchLoading(false));
        setSearchTerm("");
        setSelectedCategory("");
      });
  };

  return (
    <div>
      <header className=" sticky-top m-b-3 lg-md-col-12 sm-col-4">
        <nav className="container-xl px-lg-5 px-md-4 navbar navbar-expand-lg py-3 bg-dark navbar-dark">
          <button
            className="navbar-toggler my-2 mb-3 mx-3 togglebutton"
            onClick={toggleSidebar}
            type="button"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <Link to="/" className="logoIcon" onClick={fetchUserData}>
            <img src={Logo} alt="logo image" />
          </Link>
          <div className="container-fluid  navbarContainer px-1">
            <form onSubmit={(e) => handleSearch(e)} className="input-wrapper">
              <input
                id="inputSearch"
                placeholder=""
                className="inputsearch"
                value={searchTerm}
                onChange={handleInputChange}
                disabled={passLoading}
              />
              <button
                className="search-button"
                onClick={(e) => handleSearch(e)}
                disabled={searchDisable || passLoading}
              >
                <FaSearch id="search-icon" />
              </button>
            </form>

            <span
              className="plus-btn  collapse navbar-collapse "
              data-toggle="tooltip"
              data-placement="right"
              title="Add User"
            >
              <RiUserAddFill onClick={!passLoading ? addUserForm : null} />
            </span>
            <div className="collapse navbar-collapse">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0 d-flex align-items-center gap-2">
                <li className="nav-item mx-2">
                  <div className="btn-group">
                    <button
                      type="button"
                      className=" my-2 dropdown-toggle sm-md-d-none category-btn"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      disabled={passLoading}
                    >
                      <span>{selectedCategory || "Category"}</span>
                    </button>
                    <ul
                      className="dropdown-menu options category-list drop-list"
                      style={{ width: "300px" }}
                    >
                      {categories.map((category) => (
                        <>
                          <li
                            key={category.id}
                            className={`text-wrap mb-2 ${
                              selectedCategory === category.category
                                ? "active"
                                : "dropdown-item option"
                            }`}
                            value={categorySearch}
                            onClick={(e) => handleCategoryClick(e, category)}
                          >
                            <img
                              src={serviceIcon}
                              alt="service icon"
                              style={{ width: "20px" }}
                            />
                            {/* {category.category.charAt(0).toUpperCase() + category.slice(1)} */}
                            {category.category}
                          </li>
                        </>
                      ))}

                      <div className="dropdown-divider"></div>
                      <li
                        className="dropdown-item option"
                        value={categorySearch}
                        onClick={(e) => {
                          // handleCategoryClick(e, "");
                          fetchUserData();
                          setSelectedCategory("");
                        }}
                      >
                        <span>
                          <img
                            src={allServicesIcon}
                            alt="all services"
                            style={{
                              width: "30px",
                            }}
                          />
                        </span>
                        All Category
                      </li>
                    </ul>
                  </div>
                </li>
                {shouldShowEditIcon && (
                  <li
                    className={`addCategory ${
                      !authenticated ? "disabled" : ""
                    }`}
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Add Custom Category"
                  >
                    <LiaUserPlusSolid
                      onClick={authenticated ? handleOpenModal : null}
                    />
                  </li>
                )}
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <NewCateogoryModal
        handleCloseModal={handleCloseModal}
        categoryOpenModal={categoryOpenModal}
        setCatLoading={setCatLoading}
      />
    </div>
  );
};

export default Navbar;
