import instance from "./api-instace";


export const getUsers = async () => {
  return await instance.get("/users");
};
export const deleteUsers = async (id) => {
  return await instance.delete(`/delete-user/${id}`);
};
export const addUsers = async (data) => {
  const formData = new FormData();

  // Append all fields from data object to FormData
  Object.keys(data).forEach(key => {
    if (key === 'user_image' && data[key]) {
      // If user_image exists and is not null, append the File object
      formData.append('user_image', data[key]);
    } else {
      formData.append(key, data[key]);
    }
  });

  try {
    const response = await instance.post("/create-user", formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data; // Assuming your API returns data upon successful creation
  } catch (error) {
    throw error; // Handle error as needed
  }
}; 
export const updateUsers = async (id, data) => {
  const formData = new FormData();
  const { image, ...otherData } = data; // Assuming `data` has an `image` field and the rest are other data

  // Append image to FormData if it exists
  if (image) {
    formData.append('image', image);
  }

  // Log the form data entries for debugging 
  for (let pair of formData.entries()) {
    console.log(pair[0] + ': ' + pair[1]);
  }

  try {
    let response;
    if (formData.has('image')) {
      response = await instance.post(`/update-user/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    } else {
      // If there's no image, send otherData as JSON
      response = await instance.post(`/update-user/${id}`, otherData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    } 
    
    return response.data; // Assuming your API returns data upon successful update
  } catch (error) {
    throw error; // Handle error as needed
  }
};

export const uploadUsersFile = async (file) => {
  const formData = new FormData();
  formData.append('excelFile', file, file.name); // Assuming 'file' is a File object

  try {
    const response = await instance.post('/upload-users-data', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    // Handle error
    console.error('Error uploading file:', error);
    throw error;
  }
};
export const createCategory = async (data) => {
  return await instance.post("/create-category", data)
}
export const getCategories = async () => {
 return await instance.get('/category');
}
// export const searchUser = async (data, cat) => {
//   return await instance.get(`/search-users?search=${data}&category=${cat}`)
// }
export const searchUser = async (data) => {
  return await instance.get(`/search-users/?search=${data}`)
}
export const searchCategory = async (cat_id) => {
  return await instance.get(`/search-users/?category_id=${cat_id}`)
}