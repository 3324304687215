import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSidebarNavOpen: false,
};

export const sidebarSlice = createSlice({
  name: 'sidebarNav',
  initialState,
  reducers: {
    setIsSidebarNavOpen: (state, action) => {
      state.isSidebarNavOpen = action.payload; 
    },
  },
});

// Action creators are generated for each case reducer function
export const { setIsSidebarNavOpen } = sidebarSlice.actions;

export default sidebarSlice.reducer;