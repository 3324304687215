import { configureStore } from '@reduxjs/toolkit'
import scrollingReducer from './slices/scrollingSlice/scrollingSlice'
import categoryModal from './slices/newCategoryModalSlice/modalSlice'
import searchQuerySlice from './slices/searchQuerySlice'
import addUserForm from './slices/addUserSlice'
import sidebarNav from './slices/sidebarSlice'
import searchLoading from './slices/searchQuerySlice'
import userDataSlice from './slices/userDataSlice'
import passLoadingSlice from './slices/passwordLoadingSlice'

export const store = configureStore({
  reducer: {
    scrollingReducer : scrollingReducer,
    categoryModal : categoryModal,
    searchQuery : searchQuerySlice,
    addUserForm : addUserForm, 
    sidebarNav : sidebarNav,
    searchLoading : searchLoading,
    userData : userDataSlice,
    passLoadingSlice: passLoadingSlice
  }   
})