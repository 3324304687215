import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  searchQuery: '',
  searchLoading: false
};

export const searchQuerySlice = createSlice({
  name: 'searchQuery',
  initialState,
  reducers: {
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    setSearchLoading: (state, action) => {
      state.searchLoading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSearchQuery, setSearchLoading } = searchQuerySlice.actions;

export default searchQuerySlice.reducer;