import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  passLoading: false,
};

export const passwordLoadingSlice = createSlice({
  name: 'passLoading',
  initialState,
  reducers: {
    setPassLoading: (state, action) => {
      state.passLoading = action.payload; 
      
    }, 
  },
});

// Action creators are generated for each case reducer function
export const { setPassLoading } = passwordLoadingSlice.actions;

export default passwordLoadingSlice.reducer;