import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  hasMore: true,
  scrollLoading: false,
};

export const scrollingSlice = createSlice({
  name: 'scrolling',
  initialState,
  reducers: {
    setHasMore: (state, action) => {
      state.hasMore = action.payload;
    },
    setScrollLoading: (state, action) => {
      state.scrollLoading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setHasMore, setScrollLoading } = scrollingSlice.actions;

export default scrollingSlice.reducer;