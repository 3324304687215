import React from 'react'
import { PuffLoader  } from 'react-spinners';


const loader = () => {
  return (
    <div>
    <PuffLoader color="#09fccb" />
    </div>
  )
}

export default loader
