import React, { useEffect, useState } from "react";
import "../../assets/css/Sidebar.css";
import { RiUserAddFill } from "react-icons/ri";
import allServicesIcon from "../../assets/images/customer-service.png";
import serviceIcon from "../../assets/images/icons8-service-64.png";
import { RiCloseCircleFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import {
  setHasMore,
  setScrollLoading,
} from "../../store/slices/scrollingSlice/scrollingSlice";
import {
  setSearchLoading,
  setSearchQuery,
} from "../../store/slices/searchQuerySlice";
import { LiaUserPlusSolid } from "react-icons/lia";
import { useLocation } from "react-router-dom";
import NewCateogoryModal from "../../features/newCategoryModal/NewCateogoryModal"; 
import { setShowAddUser } from "../../store/slices/addUserSlice";
import { setIsSidebarNavOpen } from "../../store/slices/sidebarSlice";
import { getCategories, getUsers, searchCategory, searchUser } from "../../api/api-route";
import { toast } from "react-toastify";
import { setAllCategories } from "../../store/slices/newCategoryModalSlice/modalSlice";

const Sidebar = () => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [categories, setCategories] = useState([]);
  const location = useLocation();
  const shouldShowEditIcon = location.pathname === "/user-update-information";
  const [sidebarCategoryModalOpen, setSidebarCategoryModalOpen] = useState(false);
  const [catLoading, setCatLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");

  const { allCategories } = useSelector((state) => state.categoryModal);  

  const isOpen = useSelector((state) => state.sidebarNav.isSidebarNavOpen);

  const onSideNavbarClose = () => {
    dispatch(setIsSidebarNavOpen(false));
  }; 
 

  useEffect(() => {
    getCategories()
      .then((res) => {
        const transformedData = res.data.map((cat) => ({
          value: cat.id,
          label: cat.category,
        })); 
        dispatch(setAllCategories(transformedData));
      })
      .catch((err) => {
        console.log("error in get categroy api", err);
      });
  }, [dispatch, catLoading]); 

  const handleSidebarOpenModal = () => {
    setSidebarCategoryModalOpen(true); 
  };

  const handleSidebarCloseModal = () => {
    setSidebarCategoryModalOpen(false); 
  };

  const addUserForm = (event) => {
    dispatch(setShowAddUser(true));
    document.body.style.overflowY = "hidden";
    if (event) {
      event.stopPropagation();
    }
  };

  const handleCategoryClick = async (category) => {   
    dispatch(setHasMore(true));
    dispatch(setScrollLoading(false));
    await handleSearch(category.value);
  };

  const handleSearch = async (category) => { 
    try {
      dispatch(setSearchLoading(true));

      const response = await searchCategory(category);
      if (Array.isArray(response.data)) { 
        
        // If the response is an array, set the new data 
      dispatch(setSearchQuery(response.data));
      } else if (typeof response.data === 'string' && response.data === "Not found") { 
        
        // If the response is the "Not found" string, clear the data  
      dispatch(setSearchQuery([]));
      }
      // dispatch(setSearchQuery(response.data));
      toast.success("search succussfully");
    } catch (error) {
      console.log("error in search", error);
      return;
    } 
  };

  useEffect(() => {
    const handleBodyScroll = (event) => {
      if (isOpen) {
        event.preventDefault();
      }
    };

    // Add event listener to disable body scrolling when the sidebar is open
    if (isOpen) {
      document.body.style.overflow = "hidden";
      document.addEventListener("scroll", handleBodyScroll, { passive: false });
    } else {
      document.body.style.overflow = "auto";
      document.removeEventListener("scroll", handleBodyScroll);
    }

    // Cleanup function to remove the event listener when the component unmounts or when the sidebar is closed
    return () => {
      document.body.style.overflow = "auto";
      document.removeEventListener("scroll", handleBodyScroll);
    };
  }, [isOpen]);

  return (
    <>
      <div className={`sidebar ${isOpen ? "open" : ""} `}>
        <div className="close-div">
          <h4>Category List </h4>
          <button className="btn close">
            <span
              className="close-slidebar"
              onClick={() => {
                onSideNavbarClose();
              }}
            >
              <RiCloseCircleFill />
            </span>
          </button>
        </div>
        <div className="category-list">
          <div className="add-user">
            <button
              className="btn btn-outline-none text-center"
              data-toggle="tooltip"
              data-placement="right"
              title="Add User"
              onClick={() => {
                addUserForm();
                onSideNavbarClose();
              }}
            >
              Add User
            </button>
            <span
              onClick={() => {
                addUserForm();
                onSideNavbarClose();
              }}
              style={{ fontSize: "30px" }}
            >
              <RiUserAddFill />
            </span>
          </div>
          {shouldShowEditIcon && (
            <div className="add-user">
              <button
                className="btn btn-outline-none text-center"
                data-toggle="tooltip"
                data-placement="right"
                title="Add User"
                onClick={() => {
                  handleSidebarOpenModal();
                  onSideNavbarClose();
                }}
              >
                Add Category
              </button>
              <span
                onClick={() => {
                  onSideNavbarClose();
                }}
                style={{ fontSize: "30px" }}
              >
                <LiaUserPlusSolid onClick={handleSidebarOpenModal} />
              </span>
            </div>
          )}

          <ul className="ul-categoryList">
            {allCategories.map((category, index) => (
              <li
                key={index}
                className="dropdown-item option"
                value={searchTerm}
                onClick={() => {
                  handleCategoryClick(category);
                  onSideNavbarClose();
                }}
              >
                <span>
                  <img
                    src={serviceIcon}
                    alt="serviceIcon"
                    style={{ width: "20px" }}
                  />
                </span>
                {/* {category.charAt(0).toUpperCase() + category.slice(1)} */}
                {category.label}
              </li>
            ))}
            <hr />
            <li
              value={searchTerm}
              onClick={() => {
                handleCategoryClick("");
                onSideNavbarClose();
              }}
            >
              <span style={{ marginRight: "20px", fontSize: "30px" }}>
                {" "}
                <img
                  src={allServicesIcon}
                  alt="allServicesIcon"
                  style={{ width: "40px" }}
                />
              </span>
              All Categories
            </li>
          </ul>
        </div>
      </div>
      <NewCateogoryModal
        handleCloseModal={handleSidebarCloseModal}
        sidebarCategoryModalOpen={sidebarCategoryModalOpen}
        setCatLoading={setCatLoading}
      />
    </>
  );
};

export default Sidebar;
