import React, { useState, useRef } from "react";
import * as XLSX from "xlsx";
import { IoMdDownload } from "react-icons/io";
import { MdFileUpload } from "react-icons/md";
import Loader from "../../features/loader/loader";
import { ToastContainer, toast } from "react-toastify";
import "../../assets/css/HomeStyle.css";
import "../../assets/css/user-excel-password.css";
import Resizer from "react-image-file-resizer";
import { uploadUsersFile } from "../../api/api-route";


const UserExcelSheet = () => {
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [excelData, setExcelData] = useState([
    {
      first_name: "",
      last_name: "",
      category_name: "",
      phone_number: "",
      email: "",
      description: "",
      user_image: "",
    },
  ]);
  const [fileSelected, setFileSelected] = useState(false);


  const handleDownload = () => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "UserData.xlsx");
  };

  const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      100,
      100,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });


  const handleUpload = (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    setExcelData(file);
    setFileSelected(true);
  }; 

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!fileSelected) {
      toast.error('Please select the excel file');
      return;
      }
    setLoading(true);
    try {
      await uploadUsersFile(excelData);
      toast.success('File uploaded successfully');
    } catch (error) {
      toast.error('Failed to upload file. Please try again.');
    } finally {
      setFileSelected(false);
      setLoading(false);
      fileInputRef.current.value = null;
    }
  };



  return (
    <div className="home container password">
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="download-upload-cardDiv">
        <button className="btn btn-dark p-2" onClick={handleDownload}>
          Download
          <span className="download-upload-icon">
            <IoMdDownload />
          </span>
        </button>
        <input
          className="form-control outline-dark file-choose-input"
          type="file"
          accept=".xlsx,.xls"
          ref={fileInputRef}
          onChange={handleUpload}
        />

        <button className="btn btn-dark" onClick={handleSubmit}>
          Upload
          <span className="download-upload-icon">
            <MdFileUpload />
          </span>
        </button>
      </div>
      {loading && (
        <div className="loading-icon">          
            <Loader/>
        </div>
      )}
    </div>
  );
};

export default UserExcelSheet;
