import { useEffect, useState } from 'react';

const useShuffleColor = () => {
  const [shuffledColors, setShuffledColors] = useState([]);

  const randomColor = [
    "#F3E5F5",
    "#E1BEE7",
    "#CE93D8",
    "#B39DDB",
    "#9FA8DA",
    "#90CAF9",
    "#81D4FA",
    "#80DEEA",
    "#80CBC4",
    "#A5D6A7",
    "#C5E1A5",
    "#E6EE9C",
    "#FFF59D",
    "#FFE082",
    "#FFCC80",
    "#FFAB91",
  ];

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  useEffect(() => {
  
    setShuffledColors(shuffleArray([...randomColor]));
  }, []);

  return {
    shuffledColors
  };
};

export default useShuffleColor;
