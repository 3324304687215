import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  categoryModalRef : null,
  newCategoryValue : "",
  allCategories: [
    { label: 'Custom Category', value: 'custom' },
  ]
};

export const modalSlice = createSlice({
  name: 'modalSlice',
  initialState,
  reducers: {
    setCategoryModalRef: (state, {payload}) => {
      state.categoryModalRef = payload;
    },
    setNewCategoryValue : (state, {payload}) => {
      state.newCategoryValue = payload
    },
    appendNewCategory: (state, { payload }) => {
      const existingCategories = state.allCategories.filter(cat => cat.value !== 'custom');
      state.allCategories = [...existingCategories, payload, { label: 'Custom Category', value: 'custom' }];
      // Ensure "Custom Category" is always at the end
    },
    setAllCategories: (state, { payload }) => {
      state.allCategories = payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const { 
  setCategoryModalRef, 
  setNewCategoryValue, 
  appendNewCategory ,
  setAllCategories
} = modalSlice.actions;

export default modalSlice.reducer;