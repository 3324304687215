import React from "react";
import Category from "../../components/category/Category";
import "../../assets/css/HomeStyle.css";

const Home = () => {
  return (
    <div className="home-parent">
      <div className="home">
        <div>
          <Category />
        </div>
      </div>
    </div>
  );
};

export default Home;
