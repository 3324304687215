import React, { useEffect, useRef, useState } from "react";
import "../../assets/css/Categorystyle.scss";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../features/loader/loader";
import { FaAngleRight, FaPhone } from "react-icons/fa6";
import userimg from "../../assets/images/images-removebg-preview.png";
import usernotfound from "../../assets/images/49639853_9276420-ai.png";
import Collapse from "react-bootstrap/Collapse";
import AddCategory from "./AddCategory";
import { ToastContainer, toast } from "react-toastify";
import { FaChevronDown, FaChevronUp, FaTools, FaEdit } from "react-icons/fa";
import { IoMdMail, IoMdClose } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import ScrollingFunction from "../../features/scrollingFunction/scrollingFunction";
import LoadingModal from "../../features/loadingModal/LoadingModal";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Measure from "react-measure";
import useShuffleColor from "../../hooks/useShuffleColor/useShuffleColor";
import { setEditFormVisible } from "../../store/slices/addUserSlice";
import {
  getUsers,
  deleteUsers,
  getCategories,
  searchUser,
  updateUsers,
} from "../../api/api-route";
import { baseImgUrl } from "../../data/Contant";
import { setPassLoading } from "../../store/slices/passwordLoadingSlice"
import { setSearchLoading } from "../../store/slices/searchQuerySlice";

const Category = ({ onClose }) => {
  const dispatch = useDispatch();
  const [openState, setOpenState] = useState({});
  const [imageupload, setImageUpload] = useState({});
  const [selectedUser, setSelectedUser] = useState(null);
  const collapseRef = useRef(null);
  const cardRef = useRef({});
  const location = useLocation();
  const shouldShowEditIcon = location.pathname === "/user-update-information";
  const { visibleUsers } = ScrollingFunction();
  const { shuffledColors } = useShuffleColor();
  const [imageConverted, setImageConverted] = useState(false);
  const [loadingModalShow, setLoadingModalShow] = useState(false);

  const showAddUser = useSelector((state) => state.addUserForm.showAddUser);
  const editFormVisible = useSelector(
    (state) => state.addUserForm.editFormVisible
  );

  const [userNewData, setUserNewData] = useState();
  const [loadingData, setLoadingData] = useState(false);
  const [loadingDataState, setLoadingDataState] = useState(false);
  const [allCategries, setAllCategories] = useState();
  const searchQuery = useSelector((state) => state.searchQuery.searchQuery);
  const searchLoading = useSelector((state) => state.searchQuery.searchLoading);
  const userData = useSelector((state) => state.userData.userData);  
  
  // fetch function for all users
  const fetchUserData = async () => { 
    
    setLoadingDataState(true);
    try {
      const response = await getUsers();
      setUserNewData(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => { 
        setLoadingDataState(false);
      }, 1000);
      dispatch(setSearchLoading(false));
    }
  };

  useEffect(() => {
    dispatch(setPassLoading(false));
    fetchUserData();
  }, [location.pathname]);

  useEffect(() => {
    setUserNewData(searchQuery);
    setLoadingData(searchLoading);
  }, [searchQuery]);

  useEffect(() => {
    setUserNewData(userData);
  }, [userData]);

  useEffect(() => {
    getCategories()
      .then((res) => {
        setAllCategories(res.data);
      })
      .catch((err) => {
        console.log("error in get categroy api", err);
      });
  }, []);

  const handlePhoneClick = (phoneNumber) => {
    // Use the tel URI scheme to initiate a phone call
    window.location.href = `tel:${phoneNumber}`;
  };

  const toggleOpenState = (id) => {
    // Close all other cards
    const isCurrentlyOpen = openState[id];

    const newOpenState = Object.keys(openState).reduce((acc, key) => {
      if (key !== id && openState[key]) {
        acc[key] = false;
      } else {
        acc[key] = openState[key];
      }
      return acc;
    }, {});

    newOpenState[id] = !isCurrentlyOpen;

    setOpenState(newOpenState);
  };

  useEffect(() => {
    let handler = (e) => {
      const clickedCard = e.target.closest(".userList-mainCard");
      if (!clickedCard) {
        // Click occurred outside the card, close all toggle collapses
        setOpenState({});
        return;
      }

      // Click occurred inside the card, extract the cardId
      const cardId = clickedCard.dataset.cardId;

      // Close the toggle collapse for the clicked card
      setOpenState((prevState) => ({
        ...prevState,
        [cardId]: false,
      }));
    };

    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []); 

  const handleEditForm = (userData) => {
    dispatch(setEditFormVisible(true));
    setSelectedUser(userData);
  };

  const handleDeleteUser = async (item) => {
    try {
      const response = await deleteUsers(item.id); 
      toast.success(response.data.message);
      if (response.data.error) {
        toast.error(response.data.error);
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    } catch (error) {
      toast.error("Please try again!");
    } finally {
      setTimeout(() => {
        fetchUserData();
      }, 1000);
    }
  };

  if (editFormVisible) {
    document.body.style.overflowY = "hidden";
  } else {
    document.body.style.overflowY = "auto";
  }

  return loadingDataState ? (
    <div className="loading">
      <Loader />
    </div>
  ) : (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <LoadingModal
        setLoadingModalShow={setLoadingModalShow}
        loadingModalShow={loadingModalShow}
      />
      <div className=" userList container ">
        {Array.isArray(userNewData) && userNewData.length > 0 ? (
          <ResponsiveMasonry
            columnsCountBreakPoints={{
              300: 1,
              500: 1,
              700: 2,
              912: 3,
              1368: 3,
            }}
          >
            <Masonry columnsCount={3} gutter="10px">
              {shuffledColors.length > 0
                ? userNewData.slice(0, visibleUsers).map((item, id) => (
                    <Measure key={item.key}>
                      {({ measureRef }) => (
                        <div
                          className="userList-mainCard clicked-inside"
                          key={item.id}
                          style={{
                            backgroundColor:
                              shuffledColors[id % shuffledColors.length],
                          }}
                          ref={(node) => {
                            measureRef(node);
                            cardRef.current = node;
                          }}
                        >
                          <div className="userList-mainCard-header">
                            <div className="userList-mainCard-header-image">
                              {item.user_image ? (
                                item.user_image.startsWith("http") ? (
                                  <img
                                    src={item.user_image}
                                    alt="user img.."
                                    className="img"
                                  />
                                ) : (
                                  <img
                                    src={`${baseImgUrl}/${item.id}/${item.user_image}`}
                                    alt="user img.."
                                    className="img"
                                  />
                                )
                              ) : (
                                <img
                                  src={userimg}
                                  alt="default img..."
                                  className="img"
                                />
                              )}
                            </div>
                            <div className="userList-mainCard-header-info">
                              <h3 className="user-name ">
                                <span
                                  className="truncate"
                                  title={`${item.first_name}, ${item.last_name}`}
                                >
                                  {item.first_name}&nbsp;
                                  {item.last_name}
                                </span>
                              </h3>
                              <p className="userList-mainCard-header-info-category">
                                {item.category}
                              </p>

                              <Collapse in={openState[item.id]}>
                                <div
                                  ref={collapseRef}
                                  id={`user-detail-${item.id}`}
                                  className="userList-mainCard-header-info-extrainfo"
                                >
                                  <FaPhone className="icons" />
                                  <p
                                    className="userList-mainCard-header-info-extrainfo-contact"
                                    onClick={() =>
                                      handlePhoneClick(item.phone_number)
                                    }
                                  >
                                    {" "}
                                    +91 {item.phone_number}
                                  </p>
                                  <IoMdMail className="icons" />
                                  <p
                                    className="truncate-p"
                                    title={`${item.email}`}
                                  >
                                    {item.email}
                                  </p>
                                  <FaTools className="icons" />
                                  <p
                                    className="truncate-p"
                                    title={`${item.description}`}
                                  >
                                    {item.description}
                                  </p>
                                  <button
                                    type="button"
                                    class="bg-transparent border-0 px-0 mt-3 show-more"
                                    data-toggle="modal"
                                    data-target={`#extraInfoModalCenter-${item.id}`}
                                  >
                                    Show more
                                    <FaAngleRight className="show-more-icon" />
                                  </button>
                                  <div
                                    class="modal fade"
                                    id={`extraInfoModalCenter-${item.id}`}
                                    tabindex="-1"
                                    role="dialog"
                                    aria-labelledby="exampleModalCenterTitle"
                                    aria-hidden="true"
                                  >
                                    <div
                                      class="modal-dialog modal-dialog-centered"
                                      role="document"
                                    >
                                      <div class="modal-content">
                                        <div class="modal-header">
                                          <h5
                                            class="modal-title"
                                            id="exampleModalLongTitle"
                                          >
                                            User Info
                                          </h5>
                                          <button
                                            className="modalClose-icon"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                            onClick={onclose}
                                          >
                                            <IoMdClose />
                                          </button>
                                        </div>
                                        <div class="modal-body">
                                          <p className="fname mb-2">
                                            <span className="fw-bold">
                                              First Name:
                                            </span>{" "}
                                            {item.first_name || ""}
                                          </p>
                                          <p className="lname mb-2 ">
                                            <span className="fw-bold">
                                              Last Name:
                                            </span>{" "}
                                            {item.last_name || ""}
                                          </p>
                                          <em>
                                            <p className="email mb-2">
                                              <span className="fw-bold">
                                                Email:
                                              </span>{" "}
                                              {item.email || ""}
                                            </p>
                                          </em>
                                          <p className="description mb-2">
                                            <span className="fw-bold">
                                              Description:
                                            </span>{" "}
                                            {item.description || ""}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Collapse>
                            </div>
                          </div>
                          <div className="userList-mainCard-buttons">
                            <span className="userList-mainCard-buttons-phone">
                              {shouldShowEditIcon ? (
                                <div className="d-flex gap-1  align-items-center ">
                                  <span
                                    className="edit-image"
                                    onClick={() => handleEditForm(item)}
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Edit user information"
                                  >
                                    {" "}
                                    <FaEdit />
                                  </span>
                                  <span onClick={() => handleDeleteUser(item)}>
                                    <MdDelete />
                                  </span>
                                </div>
                              ) : (
                                <FaPhone
                                  onClick={() =>
                                    handlePhoneClick(item.phone_number)
                                  }
                                />
                              )}
                            </span>{" "}
                            &nbsp;
                            {openState[item.id] ? (
                              <FaChevronUp
                                className="userList-mainCard-buttons-arrow"
                                onClick={() => toggleOpenState(item.id)}
                                aria-controls={`user-detail-${item.id}`}
                                aria-expanded={openState[item.id]}
                              />
                            ) : (
                              <FaChevronDown
                                className="userList-mainCard-buttons-arrow"
                                onClick={() => toggleOpenState(item.id)}
                                aria-controls={`user-detail-${item.id}`}
                                aria-expanded={openState[item.id]}
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </Measure>
                  ))
                : null}
            </Masonry>
          </ResponsiveMasonry>
        ) : (
          <>
            <div className="nouser-found col-12 ">
              <img src={usernotfound} alt="no user found" />
              <h1 className="text-center">
                Sorry, we couldn't find any results...
              </h1>
              {searchLoading && (
                <button className="btn btn-light mt-2" onClick={fetchUserData}>Reset search</button>
              )}
            </div>
          </>
        )}
        {showAddUser && (
          <div>
            <AddCategory fetchUserData={fetchUserData} />
          </div>
        )}
        {editFormVisible && (
          <div>
            <AddCategory
              selectedUserData={selectedUser}
              fetchUserData={fetchUserData}
            />
          </div>
        )}
      </div>
    </>
  );
};
export default Category;
