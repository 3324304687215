import { useState } from "react";
import "./App.css";
import Home from "./pages/home/Home";
import Sidebar from "./layout/navbar/Sidebar";
import Navbar from "./layout/navbar/Navbar";
import Footer from "./layout/footer/Footer";
import { Routes, Route } from "react-router-dom";
import UserExcelSheet from "./pages/userExcelSheet/UserExcelSheet";
import PasswordProtection from "./pages/userExcelSheet/PasswordProtection";
import { toast, ToastContainer } from "react-toastify";
import NotFound from "./pages/404/NotFound";

function MainLayout({ authenticated, handlePasswordSubmit }) {
  return (
    <>
      <ToastContainer />
      <Navbar  authenticated={authenticated} />
      <Sidebar  authenticated={authenticated}/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/user-list"
          element={
            authenticated ? (
              <UserExcelSheet />
            ) : (
              <PasswordProtection onPasswordSubmit={handlePasswordSubmit}/>
            )
          }
        />
        <Route
          path="/user-update-information"
          element={
            authenticated ? (
              <Home />
            ) : (
              <PasswordProtection onPasswordSubmit={handlePasswordSubmit}/>
            )
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  );
}

function App() {
  const [authenticated, setAuthenticated] = useState(false);

  const handlePasswordSubmit = (enteredPassword) => {
    if (enteredPassword === 'Admin@24#') {
      setAuthenticated(true);
    } else {
      toast.error('Incorrect password. Please try again.');
    }
  };

  return (
    <div className="App">
      <Routes>
        <Route
          path="/*"
          element={
            <MainLayout
              authenticated={authenticated}
              handlePasswordSubmit={handlePasswordSubmit}
            />
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
