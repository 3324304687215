import React from 'react';
import Loader from '../loader/imageLoader';
import Modal from 'react-bootstrap/Modal';


const LoadingModal = ({loadingModalShow, setLoadingModalShow}) => {
  const handleClose = () => setLoadingModalShow(false);

  return (  
    <div>
      <Modal
        show={loadingModalShow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <Loader/>
          <h6 className='text-center mt-2'>Please wait for image upload...</h6>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default LoadingModal;
