import React from 'react'
import { BeatLoader } from 'react-spinners';


const loader = () => {
  return (
    <div className='d-flex align-items-center justify-content-center mt-2'>
      <BeatLoader color="#36d7b7" />
    </div>
  )
}

export default loader
