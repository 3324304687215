import React from 'react'
import { useNavigate } from 'react-router-dom'
import NotFoundImg from "../../assets/images/1959465_255825-P4QZO3-379-ai.svg"

const NotFound = () => {
  const navigate = useNavigate();
  
  const goHome =() => {
    navigate("/")
  }

  return (
    <div className="container-fluid min-vh-100 d-flex justify-content-center align-items-center bg-light">
  <div className="text-center px-4">
    <img 
      src={NotFoundImg} 
      alt="page not found" 
      className="img-fluid mb-4" 
      style={{maxWidth: '100%', height: 'auto', maxHeight: '70vh'}} 
    />
    <div>
      <button 
        className="text-white btn btn-lg mt-3" 
        onClick={goHome} 
        style={{backgroundColor: "#212529"}}
      >Return to Home Page</button>
    </div>
  </div>
</div>
  )
}

export default NotFound