import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showAddUser: false,
  editFormVisible : false, 
};

export const showAddUserSlice = createSlice({
  name: 'showAddUser',
  initialState,
  reducers: {
    setShowAddUser: (state, action) => {
      state.showAddUser = action.payload;
    },
    setEditFormVisible: (state, action) => {
      state.editFormVisible = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setShowAddUser, setEditFormVisible } = showAddUserSlice.actions;

export default showAddUserSlice.reducer;