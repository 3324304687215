import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { setHasMore, setScrollLoading } from '../../store/slices/scrollingSlice/scrollingSlice';

const ScrollingFunction = () => {
  const [visibleUsers, setVisibleUsers] = useState(12);
  const dispatch = useDispatch();
  const hasMore = useSelector((state) => state.scrollingReducer.hasMore);
  const scrollLoading = useSelector((state) => state.scrollingReducer.scrollLoading);

  const handleScroll = useCallback(() => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
    const threshold = 100;

    if (scrollHeight - scrollTop - threshold <= clientHeight && !scrollLoading && hasMore) {
      dispatch(setScrollLoading(true));
      loadMore();
    }
  }, [scrollLoading, hasMore, dispatch]);

  const loadMore = useCallback(() => {
    const additionalUsers = 10;
    setVisibleUsers(prevVisibleUsers => prevVisibleUsers + additionalUsers);
    dispatch(setHasMore(true));
    dispatch(setScrollLoading(false));
  }, [dispatch]);

  useEffect(() => {
    const handleScrollEvent = () => handleScroll();
    window.addEventListener("scroll", handleScrollEvent);
    return () => window.removeEventListener("scroll", handleScrollEvent);
  }, [handleScroll]);

  return {
    visibleUsers,
    hasMore,
    scrollLoading
  };
};

export default ScrollingFunction;
