import React, { useEffect, useState } from 'react'
import '../../assets/css/HomeStyle.css'
import '../../assets/css/user-excel-password.css'
import { useDispatch } from 'react-redux';
import { setPassLoading } from '../../store/slices/passwordLoadingSlice'

const PasswordProtection = ({ onPasswordSubmit }) => {
  const dispatch = useDispatch();
  const [enteredPassword, setEnteredPassword] = useState('');

  useEffect(() => {
    dispatch(setPassLoading(true));
  },[])

  const handleSubmit = (e) => {
    e.preventDefault();
    onPasswordSubmit(enteredPassword);
  };

  return (
    <div className='home password'>
      <div className='cardDiv'>
          <h2 className='mb-5'>Enter Password First</h2>
          <form onSubmit={handleSubmit}>
            <label>
              Password
              <input class="form-control mt-2 custom-input" 
                type="password"
                value={enteredPassword}
                onChange={(e) => setEnteredPassword(e.target.value)}
                placeholder='***********'
              />
            </label> <br />
            <button type="submit" className='btn btn-dark p-2  mt-4  px-5'>Submit</button>
          </form>
      </div>
    </div>
  );
};

export default PasswordProtection